<template>
  <v-container>
    <h1>{{ verb }} Subtest</h1>
    <v-sheet color="white" elevation="1" class="px-5 mt-4 rounded">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="form.test_id"
              :disabled="loading"
              :items="tests"
              :loading="testLoading"
              :rules="rules.test"
              :search-input.sync="testSearch"
              label="Test"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-autocomplete
              v-model="form.question_pack_id"
              :disabled="loading"
              :items="questionPacks"
              :loading="questionPackLoading"
              :rules="rules.questionPack"
              :search-input.sync="questionPackSearch"
              label="Question Pack"
              item-text="name"
              item-value="id"
              cache-items
              flat
              hide-no-data
            ></v-autocomplete>
            <v-text-field
              v-model="form.duration"
              :disabled="loading"
              :rules="rules.duration"
              label="Duration (in minutes)"
              class="mt-2"
              type="number"
              required
            />
            <v-text-field
              v-if="showPointForm"
              v-model="form.point"
              :disabled="loading"
              :rules="rules.point"
              label="Point per question"
              class="mt-2"
              type="number"
              required
            />
            <v-text-field
              v-if="dataId"
              v-model="form.position"
              :disabled="loading"
              :rules="rules.position"
              label="Position"
              type="number"
              required
            />

            <v-switch
              v-model="form.shuffle_question"
              :loading="loading"
              class="v-input--reverse"
            >
              <template #label>Shuffle Question</template>
            </v-switch>

            <v-switch
              v-model="form.shuffle_answer"
              :loading="loading"
              class="v-input--reverse"
            >
              <template #label>Shuffle Answer</template>
            </v-switch>

            <v-btn
              :disabled="!valid"
              :loading="loading"
              color="success"
              class="mt-2"
              type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import QuestionPack from "@/services/question-pack";
import Subtest from "@/services/subtest";
import Test from "@/services/test";

const pointRule = [
  v => !!v || "Point per question is required",
  v =>
    (v > 0 && v <= 1050) ||
    "Point per question must be greater than 0 and less than or equal to 1050"
];

export default {
  name: "Group.Form",
  data() {
    return {
      valid: false,
      form: {
        test_id: "",
        question_pack_id: "",
        duration: 0,
        point: 0,
        shuffle_question: false,
        shuffle_answer: false,
        position: 0
      },
      tests: [],
      questionPacks: [],
      rules: {
        test: [v => !!v || "Institute is required"],
        questionPack: [v => !!v || "Question Pack is required"],
        duration: [
          v => !!v || "Duration is required",
          v =>
            (v > 0 && v <= 120) ||
            "Duration must be greater than 0 and less than or equal to 120"
        ],
        point: pointRule,
        position: [
          v => !!v || "Position is required",
          v =>
            Number.isInteger(Number.parseFloat(v)) ||
            "Position must be integer",
          v =>
            (parseInt(v) >= 1 && parseInt(v) <= 99) ||
            "Position must be greater than 0"
        ]
      },
      testSearch: null,
      questionPackSearch: null,
      testLoading: false,
      questionPackLoading: false,
      loading: false,
      showPointForm: true
    };
  },
  computed: {
    verb() {
      return this.$route.params.id ? "Update" : "Create";
    },
    pastVerb() {
      return `${this.verb}d`;
    },
    dataId() {
      return this.$route.params.id;
    },
    testIdForm() {
      return this.form.test_id;
    }
  },
  watch: {
    testSearch(val) {
      val && val !== this.form.test_id && this.searchTests(val);
    },
    questionPackSearch(val) {
      val &&
        val !== this.form.question_pack_id &&
        this.searchQuestionPacks(val);
    },
    testIdForm(val) {
      const test = this.tests.find(test => test.id === val);
      console.log(val, test);
      if (test !== undefined) {
        if (test.calculationSystem !== "basic") {
          delete this.rules.point;
          this.showPointForm = false;
          return;
        }
      }
      this.rules.point = pointRule;
      this.showPointForm = true;
    }
  },
  async created() {
    this.setNavbarTitle("Manage Subtest");
    if (this.dataId) {
      const subtest = await Subtest.find(this.dataId);
      const {
        duration,
        point,
        test,
        questionPack,
        shuffleQuestion,
        shuffleAnswer,
        position
      } = subtest.data.data;
      this.form = {
        duration,
        point,
        shuffle_question: shuffleQuestion,
        shuffle_answer: shuffleAnswer,
        position
      };
      this.tests.push(test);
      this.form.test_id = test.id;
      this.questionPacks.push(questionPack);
      this.form.question_pack_id = questionPack.id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async searchTests(query) {
      this.testLoading = true;
      const tests = await Test.search(query);
      this.tests = tests.data.data.tests;
      this.testLoading = false;
    },
    async searchQuestionPacks(query) {
      this.questionPackLoading = true;
      const questionPacks = await QuestionPack.search(query);
      this.questionPacks = questionPacks.data.data.questionPacks;
      this.questionPackLoading = false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        try {
          if (this.dataId) {
            await Subtest.update(this.dataId, this.form);
          } else {
            await Subtest.create(this.form);
          }

          this.$snackbar.showMessage({
            content: `Data ${this.pastVerb.toLowerCase()}`,
            variant: "success"
          });
          this.$router.push({ name: "Subtest.Index" });
        } catch (error) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
